<template>
  <div class="row justify-content-center" v-if="dataLoaded">
    <div class="col-12">
      <div class="card card-custom">
        <div class="card-body p-0">
          <!--begin: Wizard-->
          <div
            class="wizard wizard-1"
            id="kt_wizard_v1"
            data-wizard-state="step-first"
            data-wizard-clickable="true"
          >
            <!--begin: Wizard Body-->
            <div
              class="row justify-content-center my-10 px-8 my-lg-15 px-lg-10"
            >
              <div class="col-xl-12">
                <!--begin: Wizard Form-->

                <!--begin: Wizard Step 1-->
                <div
                  class="pb-5"
                  data-wizard-type="step-content"
                  data-wizard-state="current"
                >
                  <h5
                    class="
                      font-weight-bolder
                      text-dark
                      font-size-h6 font-size-h3-lg
                    "
                  >
                    Detail Peminjaman Alat
                  </h5>
                  <table class="table mt-8">
                    <tr>
                      <th class="pr-1">
                        <span>Tanggal Pinjam</span>
                      </th>
                      <th class="pl-0 pr-0">
                        <span>:</span>
                      </th>
                      <td class="pl-2">
                        <span>{{ detail.borrow_date_display }}</span>
                      </td>
                    </tr>
                    <tr>
                      <th class="pr-1">
                        <span>Tanggal Kembali</span>
                      </th>
                      <th class="pl-0 pr-0">
                        <span>:</span>
                      </th>
                      <td class="pl-2">
                        <span>{{ detail.return_date_display }}</span>
                      </td>
                    </tr>
                    <!-- <tr>
                      <th class="pr-1">
                        <span>Alat</span>
                      </th>
                      <th class="pl-0 pr-0">
                        <span>:</span>
                      </th>
                      <td class="pl-2">
                        <span>{{ detail.tool_name }}</span>
                      </td>
                    </tr> -->
                    <tr>
                      <th class="pr-1">
                        <span>Peminjam</span>
                      </th>
                      <th class="pl-0 pr-0">
                        <span>:</span>
                      </th>
                      <td class="pl-2">
                        <span>{{ detail.user_name }}</span>
                      </td>
                    </tr>
                    <tr>
                      <th class="pr-1">
                        <span>Petugas</span>
                      </th>
                      <th class="pl-0 pr-0">
                        <span>:</span>
                      </th>
                      <td class="pl-2">
                        <span>{{ detail.officer_name }}</span>
                      </td>
                    </tr>
                    <tr>
                      <th class="pr-1">
                        <span>Status</span>
                      </th>
                      <th class="pl-0 pr-0">
                        <span>:</span>
                      </th>
                      <td class="pl-2">
                        <span>{{ detail.borrower_status_name }}</span>
                      </td>
                    </tr>
                    <tr>
                      <th class="pr-1">
                        <span>Keterangan</span>
                      </th>
                      <th class="pl-0 pr-0">
                        <span>:</span>
                      </th>
                      <td class="pl-2">
                        <span>{{ detail.description }}</span>
                      </td>
                    </tr>
                  </table>
                </div>
                <!--end: Wizard Step 1-->

                <template v-if="detail.tool_id.length > 0">
                  <h5
                    class="
                      font-weight-bolder
                      text-dark
                      font-size-h6 font-size-h3-lg
                    "
                  >
                    Alat yang dipinjam
                  </h5>
                  <div class="row">
                    <div class="col-12">
                      <table class="table table-borderless">
                        <thead class="bg-light">
                        <tr>
                          <th>No</th>
                          <th>Alat</th>
                          <th>Status</th>
                          <th>Tanggal Kembali</th>
                          <th>Kondisi Kembali</th>
                        </tr>
                        </thead>
                        <tr v-for="(tool, i) in detail.tool_id" :key="tool.id">
                          <td>{{ i + 1 }}</td>
                          <td>{{ tool.name }}</td>
                          <td>
                            {{
                              tool.tool_status_id == 1 ? "Dipinjam" : "Selesai"
                            }}
                          </td>
                          <td>
                            {{
                              tool.return_date != null ? tool.return_date : "-"
                            }}
                          </td>
                          <td>
                            {{
                              tool.tool_status_id == 1
                                ? "Baik"
                                : tool.tool_status_id == 2
                                ? "Rusak"
                                : "-"
                            }}
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </template>

                <template v-if="detail.lab_material_id.length > 0">
                  <h5
                    class="
                      font-weight-bolder
                      text-dark
                      font-size-h6 font-size-h3-lg
                    "
                  >
                    Bahan yang dipakai
                  </h5>
                  <div class="row">
                    <div class="col-12">
                      <table class="table table-borderless">
                        <thead class="bg-light">
                          <tr>
                            <th>No</th>
                            <th>Bahan</th>
                            <th>Jumlah</th>
                          </tr>
                        </thead>
                        <tr
                          v-for="(lab_material, i) in detail.lab_material_id"
                          :key="lab_material.id"
                        >
                          <td>{{ i + 1 }}</td>
                          <td>{{ lab_material.name }}</td>
                          <td>{{ lab_material.quantity }}</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </template>

                <!--begin: Wizard Actions -->
                <div class="d-flex justify-content-between border-top pt-10">
                  <div>
                    <b-button
                      type="button"
                      class="ml-2"
                      variant="primary"
                      @click="$router.push('/tool-borrowers')"
                    >
                      Tutup
                    </b-button>
                    <b-button
                      type="button"
                      variant="outline-primary"
                      class="ml-2 btn-hover-secondary"
                      @click="
                        $router.push('/tool-borrowers/edit/' + $route.params.id)
                      "
                      v-if="btnAccess"
                    >
                      Edit
                    </b-button>
                  </div>
                </div>
                <!--end: Wizard Actions -->
                <!--end: Wizard Form-->
              </div>
            </div>
            <!--end: Wizard Body-->
          </div>
        </div>
        <!--end: Wizard-->
      </div>
    </div>
  </div>
</template>

<style>
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import module from "@/core/modules/CrudModule.js";

export default {
  data() {
    return {
      detail: {},
      dataLoaded: false,
      // access
      btnAccess: false,
    };
  },
  methods: {
    async get() {
      this.detail = await module.get(
        "api/tool-borrowers/" + this.$route.params.id
      );
      // If Data Not Found
      if (this.detail == null) {
        // Redirect To List
        this.$router.push("/tool-borrowers");
      } else {
        this.dataLoaded = true;
      }
    },

    // access
    checkAccess() {
      let access_right_user = window.localStorage.getItem(
        "access_right_display"
      );
      let access_right = JSON.parse(access_right_user);
      for (let a = 0; a < access_right.length; a++) {
        if (access_right[a] == "1111") {
          this.btnAccess = true;
        }
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Peminjaman Alat dan Bahan", route: "/tool-borrowers" },
      { title: "Detail" },
    ]);

    this.get();
    this.checkAccess();
  },
};
</script>
